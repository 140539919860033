import React, { CSSProperties } from 'react'
import { CenterFlex, ColFlex, ExpRowFlex, RowFlex } from './Misc'
import { palette, standardGap } from '../Misc/style'
import { ChatHistory, ChatHistoryBox, ChatMessage, MessageContent } from './ChatHistoryBox'
import { isMobile } from 'react-device-detect'
import { LanguageSelector } from './LanguageSelector'
import { ProxyLang, enabledProxyLanguages, getAzureVoice, makeGetLocString } from '../Localization/locDef'
import { ProxyLoginResp, decodeProxyMessageResponse, requestProxyLogin, sendMessageToProxy } from '../Misc/utils'
import { sttFromMic, tts } from '../Misc/azureSpeech'
import { OverlayScreen } from './OverlayScreen'
import { AppConfig } from '../configDef'


const idleVideoUrl = 'videos/Idle.mp4'
const talkVideoUrl = 'videos/Talk.mp4'


// porcata per evitare chiamate multiple quando si aggiorna la pagina
let initialMessageSent = false

const getSessionIdFromUrlStr = (urlStr: string) => {
    const url = new URL(window.location.href)
    return url.searchParams.get('id')
}
const sessionIdFromUrl = getSessionIdFromUrlStr(window.location.href)

export const MainScreen = () => {
    const [language, setLanguage] = React.useState(enabledProxyLanguages[0])
    const [userTextInput, setUserTextInput] = React.useState('')
    const [chatHistory, setChatHistory] = React.useState<ChatHistory>([])
    const chatHistoryRef = React.useRef<ChatHistory>([])
    const [talking, setTalking] = React.useState(false)
    const [micState, setMicState] = React.useState<'inactive' | 'starting' | 'listening'>('inactive')
    const [overlayContent, setOverlayContent] = React.useState<null | MessageContent>(null)

    const [proxyLoginResp, setProxyLoginResp] = React.useState<ProxyLoginResp | null>(null)

    const [firstInteractionDone, setFirstInteractionDone] = React.useState(false)

    const ls = makeGetLocString(language)

    // roba resize
    const [innerSize, setInnerSize] = React.useState({w: window.innerWidth, h: window.innerHeight})
    const resizeTimeoutRef = React.useRef<undefined | ReturnType<typeof setTimeout>>(undefined)
    React.useEffect(() => {
        const doResize = () => {
            clearTimeout(resizeTimeoutRef.current)
            resizeTimeoutRef.current = setTimeout(() => {
                // setInnerSize({w: window.innerWidth, h: window.innerHeight})
            }, 700)
            setInnerSize({w: window.innerWidth, h: window.innerHeight})
        }
        window.addEventListener('resize', doResize)
        window.screen.orientation.addEventListener('change', doResize)
    }, [])

    // roba idle
    const idleTimeoutRef = React.useRef<undefined | ReturnType<typeof setTimeout>>(undefined)
    React.useEffect(() => {
        if (!talking) {
            if (idleTimeoutRef.current === undefined) {
                idleTimeoutRef.current = setTimeout(() => {
                    sendMessage('client idle state', 'automatic')
                }, AppConfig.idleTimeoutSeconds * 1000)
            }
        } else {
            clearTimeout(idleTimeoutRef.current)
            idleTimeoutRef.current = undefined
        }
    }, [talking])

    // roba messaggio iniziale
    React.useEffect(() => {
        if (firstInteractionDone && !initialMessageSent) {
            sendMessage(ls('autoGreeting'), 'automatic')
            initialMessageSent = true
        }
    }, [firstInteractionDone])

    const sendToVampServerWithAuth = async (messageText: string) => {
        // i setter dello stato sono asincroni, quindi mette il token in questa variabile per usarlo subito
        let loginResp: ProxyLoginResp
        if (proxyLoginResp === null) {
            console.log("getting access token and session id")
            loginResp = await requestProxyLogin(AppConfig.proxyUrl)
            setProxyLoginResp(loginResp)
        } else {
            loginResp = proxyLoginResp
        }
        if (loginResp !== null) {
            const sessionId = sessionIdFromUrl ?? loginResp.session_id
            try {
                return await sendMessageToProxy(AppConfig.proxyUrl, sessionId, language, loginResp.access_token, messageText)
            } catch (e) {
                console.log("sendToProxyServer failed, trying to redo login")
                loginResp = await requestProxyLogin(AppConfig.proxyUrl)
                setProxyLoginResp(loginResp)
                return await sendMessageToProxy(AppConfig.proxyUrl, sessionId, language, loginResp.access_token, messageText)
            }
        } else {
            throw new Error("could not get access token")
        }
    }

    const sendMessage = async (text: string, source: 'automatic' | 'text' | 'speech') => {
        if (source === 'text') {
            chatHistoryRef.current = [...chatHistoryRef.current, {direction: 'sent', contents: [{type: 'text', text: text}]}]
            setChatHistory(chatHistoryRef.current)
        }

        const vampResp = await sendToVampServerWithAuth(text)
        const decodedResp = decodeProxyMessageResponse(vampResp)
        if (decodedResp)
        {
            chatHistoryRef.current = [
                ...chatHistoryRef.current,
                source === 'speech' ? {direction: 'sent', contents: [{type: 'text', text: decodedResp.question}]} : null,
                {direction: 'received', contents: [
                    decodedResp.readableText !== '' ? {type: 'text', text: decodedResp.readableText} : null,
                    // {type: 'image', url: 'images/flag-ITA.png'},
                    // {type: 'pdf', url: 'documents/paper2.pdf'},
                    // {type: 'choice', text: "Scelta"},
                ].filter(e => e !== null) as MessageContent[]},
            ].filter(e => e !== null) as ChatMessage[]
            setChatHistory(chatHistoryRef.current)

            tts(
                decodedResp.ttsText,
                getAzureVoice(language).lang,
                getAzureVoice(language).voice,
                () => {
                    setTalking(true)
                },
                () => {
                    setTalking(false)
                })
        }
    }

    const isHorizontalLayout = () => innerSize.w >= innerSize.h

    const micButtonBg = palette.inputBg
    const micButtonHoverBg = micState === 'inactive' ? palette.buttonBgHovered : micButtonBg

    const micButtonHeight = 'calc(min(4em, 12vw, 12vh))'
    const inputBar = (style?: CSSProperties) => {
        return (
            <RowFlex style={{...style, alignSelf: 'stretch', alignItems: 'center'}}>
                <input
                    style={{display: 'flex', flex: 1, fontSize: '1.2em', margin: 0, border: 'none', borderRadius: '1em', height: '1.8em', minWidth: 0, paddingLeft: '1em', paddingRight: '1em'}} 
                    value={userTextInput}
                    onChange={ev => setUserTextInput(ev.target.value)}
                    onKeyUp={ev => {
                        if (ev.key === 'Enter' && userTextInput !== '') {
                            sendMessage(userTextInput, 'text')
                            setUserTextInput('')
                            if (isMobile) {
                                ;(ev.target as any).blur() // per mandare via la virtual keyboard su mobile
                            }
                        }
                    }}
                />
                <CenterFlex
                    style={{height: micButtonHeight, width: micButtonHeight, borderRadius: '2em', backgroundColor: micButtonBg, marginLeft: standardGap}}
                    hoverBgColor={micButtonHoverBg}
                    onPress={() => {
                        if (micState === 'inactive') {
                            setMicState('starting')
                            sttFromMic(getAzureVoice(language).lang, (recEvent) => {
                                if (recEvent.type === 'starting') {
                                    // niente perchè micState è già in starting
                                } else if (recEvent.type === 'started') {
                                    setMicState('listening')
                                } else if (recEvent.type === 'ended') {
                                    const resString = recEvent.result
                                    if (resString !== null) {
                                        sendMessage(resString, 'speech')
                                    } else {
                                        console.log("nothing recognized")
                                    }
                                    setMicState('inactive')
                                } else {
                                    throw new Error()
                                }
                            })
                        }
                    }}
                >
                    <img alt='mic' src='images/Mic.svg' style={{height: '75%', display: micState === 'inactive' ? 'flex' : 'none'}} />
                    <img alt='loading' src='images/loading.gif' style={{height: '75%', display: micState === 'starting' ? 'flex' : 'none'}} />
                    <img alt='listening' src='images/recording.gif' style={{height: '50%', display: micState === 'listening' ? 'flex' : 'none'}} />
                </CenterFlex>
            </RowFlex>
        )
    }

    const twoVideos = true
    const videoPlayer = (style?: CSSProperties) => {
        return (
            <div
                style={{position: 'relative', display: 'flex', margin: 0, borderRadius: '100%', aspectRatio: 1, objectFit: 'cover', ...style}}
            >
                <video
                    style={{display: 'flex', margin: 0, borderRadius: '100%', aspectRatio: 1, objectFit: 'cover', width: '100%', height: '100%'}}
                    autoPlay
                    muted={true}
                    loop={true}
                    src={twoVideos ? idleVideoUrl : (talking ? talkVideoUrl : idleVideoUrl)}
                />
                {twoVideos &&
                    <video
                        style={{/*opacity: talking ? 1 : 0, */zIndex: 1, position: 'absolute', display: talking ? 'flex' : 'none', margin: 0, borderRadius: '100%', aspectRatio: 1, objectFit: 'cover', width: '100%', height: '100%'}}
                        autoPlay
                        muted={true}
                        loop={true}
                        src={talkVideoUrl}
                    />
                }
            </div>
        )
    }

    const onChatContentPress = (content: MessageContent) => {
        if (content.type === 'pdf') {
            console.log("pdf pressed")
            setOverlayContent(content)

            window.history.pushState({}, 'overlay', null)
            window.addEventListener('popstate', function popOverlay() {
                setOverlayContent(null)
                window.removeEventListener('popstate', popOverlay);
            })
        }
    }

    const bgImageStyle = {backgroundImage: 'url(images/main_bg.jpg)', backgroundSize: 'cover', backgroundPosition: 'center'}
    return (
        <div style={{position: 'relative', display: 'flex', minHeight: innerSize.h, height: innerSize.h, fontSize: '1em'}}>
            {isHorizontalLayout() ?
                <RowFlex expand style={{...bgImageStyle, padding: '10vh 20vh'}}>
                    <ColFlex style={{height: '100%', flex: 0.33}}>
                        <LanguageSelector lang={language} onLangSelected={lang => setLanguage(lang)} containerStyle={{width: micButtonHeight, height: micButtonHeight}} />
                        {videoPlayer({maxWidth: '70%', maxHeight: '50%', alignSelf: 'center'})}
                        <div style={{maxWidth: '80%', maxHeight: '15%', marginTop: 'auto', display: 'flex', alignItems: 'flex-end', justifyItems: 'flex-end'}}>
                            <img alt='logo' src='images/logo.png' style={{objectFit: 'contain', objectPosition: 'left', width: '100%', height: '100%'}} />
                        </div>
                        <RowFlex style={{width: '100%', height: 'calc(min(7.5vh, ' + micButtonHeight + ' + ' + standardGap + '))', alignItems: 'flex-end', color: palette.poweredByColor}}>
                            Powered by Archetype AI
                        </RowFlex>
                    </ColFlex>
                    <ColFlex style={{height: '100%', flex: 0.67, paddingLeft: '10vh', backgroundColor: '#00000000'}}>
                        <CenterFlex style={{flex: 1}}>
                            <ChatHistoryBox chatHistory={chatHistory} onContentPress={onChatContentPress} />
                        </CenterFlex>
                        {inputBar({paddingTop: standardGap})}
                        {!firstInteractionDone &&
                            <CenterFlex
                                style={{position: 'absolute', inset: 0, left: '10vh', backgroundColor: '#000000aa', fontSize: '2em', color: palette.chatSentText}}
                                onPress={() => setFirstInteractionDone(true)}
                            >
                                {ls('pressToStart')}
                            </CenterFlex>
                        }
                    </ColFlex>
                </RowFlex>
            :
                <ColFlex expand style={{...bgImageStyle}}>
                    <ColFlex style={{flex: 0.4}}>
                        <RowFlex style={{padding: '1em'}}>
                            <LanguageSelector lang={language} onLangSelected={lang => setLanguage(lang)} containerStyle={{zIndex: 10, width: micButtonHeight, height: micButtonHeight}} />
                            <img alt='logo' src='images/logo.png' style={{height: micButtonHeight, marginLeft: 'auto'}} />
                        </RowFlex>
                        {videoPlayer({marginBottom: standardGap, alignSelf: 'center', width: '70%'})}
                    </ColFlex>
                    <ColFlex style={{height: '100%', flex: 0.6}}>
                        <CenterFlex style={{flex: 1}}>
                            <ChatHistoryBox chatHistory={chatHistory} onContentPress={onChatContentPress} />
                        </CenterFlex>
                        {inputBar({padding: standardGap})}
                        {!firstInteractionDone &&
                            <CenterFlex
                                style={{position: 'absolute', inset: 0, backgroundColor: '#000000aa', fontSize: '4em', color: palette.chatSentText}}
                                onPress={() => setFirstInteractionDone(true)}
                            >
                                {ls('pressToStart')}
                            </CenterFlex>
                        }
                    </ColFlex>
                </ColFlex>
            }
            {overlayContent && <OverlayScreen horizontalLayout={isHorizontalLayout()} content={{type: 'pdf', url: 'documents/paper2.pdf'}} onClosePress={() => setOverlayContent(null)} containerStyle={{zIndex: 20}} />}
        </div>
    )
}
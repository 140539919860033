import React from 'react'
import { palette } from '../Misc/style'
import { BaseFlex, RowFlex } from './Misc'

export type ContentText = {
    type: 'text',
    text: string,
}
export type ContentImage = {
    type: 'image',
    url: string,
}
export type ContentPdf = {
    type: 'pdf',
    url: string,
}
export type ContentChoice = {
    type: 'choice',
    text: string,
}
export type MessageContent =
    ContentText |
    ContentImage |
    ContentPdf |
    ContentChoice

export type ChatMessage = {
    direction: 'sent' | 'received',
    contents: MessageContent[],
}
export type ChatHistory = ChatMessage[]

export const ChatHistoryBox: React.FC<{chatHistory: ChatHistory, onContentPress: (c: MessageContent) => void}> = props => {
    const baseMargin = (mult: number) => 'calc(min(2vw, 2vh) * ' + mult + ')'
    const halfVertBalloonMargin = baseMargin(0.5)
    const bigBalloonMargin = baseMargin(6)
    const smallBalloonMargin = baseMargin(3)
    const balloonBorderRadius = baseMargin(1)
    const renderMessage = (m: ChatMessage, index: number) => {
        const directionStyle: React.CSSProperties = m.direction === 'received' ?
            {
                textAlign: 'left',
                marginLeft: smallBalloonMargin,
                marginRight: bigBalloonMargin,
            }
        :
            {
                textAlign: 'right',
                marginLeft: bigBalloonMargin,
                marginRight: smallBalloonMargin,
            }
        const textStyle: React.CSSProperties = m.direction === 'received' ?
            {
                backgroundColor: palette.chatReceivedBg,
                color: palette.chatReceivedText,
                alignSelf: 'flex-start',
            }
        :
            {
                backgroundColor: palette.chatSentBg,
                color: palette.chatSentText,
                alignSelf: 'flex-end',
            }
        return <div key={index} style={{...directionStyle, marginTop: halfVertBalloonMargin, marginBottom: halfVertBalloonMargin, display: 'flex', flexDirection: 'column', position: 'relative'}}>
            {/* <div style={{position: 'absolute', top: 0, [m.direction === 'received' ? 'left' : 'right']: baseMargin(-1), height: baseMargin(1), width: baseMargin(1), backgroundColor: 'red'}} /> */}
            <div style={{
                ...textStyle,
                paddingTop: halfVertBalloonMargin,
                paddingBottom: halfVertBalloonMargin,
                paddingLeft: baseMargin(1.5),
                paddingRight: baseMargin(1.5),
                borderRadius: balloonBorderRadius,
                display: 'flex',
                flexDirection: 'column',
                gap: baseMargin(1),
            }}>
                {
                    m.contents.map((content, index) => {
                        return (
                            content.type === 'text' ?
                                <div key={index}>{content.text}</div> :
                            content.type === 'image' ?
                                <img key={index} alt='image' src={content.url} style={{width: baseMargin(15)}} /> :
                            content.type === 'pdf' ?
                                <BaseFlex key={index} onPress={() => props.onContentPress(content)} hoverBgColor={palette.buttonBgHovered} style={{borderRadius: baseMargin(1)}}>
                                    <img alt='pdf' src='images/pdf-icon.png' style={{width: baseMargin(10)}} />
                                </BaseFlex> :
                            content.type === 'choice' ?
                                <RowFlex key={index} onPress={() => props.onContentPress(content)} style={{alignItems: 'center', borderRadius: baseMargin(1)}} hoverBgColor={palette.buttonBgHovered}>
                                    <BaseFlex style={{width: baseMargin(2), height: baseMargin(2), backgroundColor: palette.inputBg, borderRadius: baseMargin(1), marginRight: baseMargin(1)}} />
                                    {content.text}
                                </RowFlex> :
                            null
                        )
                    })
                }
            </div>
            <div style={{
                position: 'absolute',
                top: 0,
                [m.direction === 'received' ? 'left' : 'right']: baseMargin(-1),
                width: 0,
                height: 0,
                borderTop: baseMargin(1.1) + ' solid ' + textStyle.backgroundColor,
                borderLeft: baseMargin(1.1) + ' solid transparent',
                borderRight: baseMargin(1.1) + ' solid transparent',
            }} />
        </div>
    }
    return (
        <div style={{display: 'flex', position: 'absolute', inset: 0, flexDirection: 'column', backgroundColor: palette.chatBg}}>
            <div style={{display: 'flex', boxSizing: 'border-box', flexDirection: 'column-reverse', width: '100%', overflow: 'auto', padding: halfVertBalloonMargin}}>
                {
                    // per tenere scrollato in giù, usa column-reverse e inverte la lista di messaggi per vederli nell'ordine giusto
                    props.chatHistory.slice(0).reverse().map((m, i) => renderMessage(m, i))
                }
            </div>
        </div>
    )
}
import { AppConfig } from "../configDef"

export enum ProxyLang {
    ENG = 'ENG',
    GER = 'GER',
    ITA = 'ITA',
    FRA = 'FRA',
}
const allProxyLangValues = new Set([
    ProxyLang.ITA,
    ProxyLang.ENG,
    ProxyLang.GER,
    ProxyLang.FRA,
])

// il primo è quello selezionato di default
export const enabledProxyLanguages = [
    ProxyLang.ITA,
    ProxyLang.ENG,
    ProxyLang.GER,
    ProxyLang.FRA,
]


export const makeGetLocString = (lang: ProxyLang) => (strName: keyof LocStrings) => {
    const res = locStringsByLang.get(lang)?.[strName]
    if (res === undefined) throw new Error()
    return res
}

type LocStrings = {
    autoGreeting: string,
    micReady: string,
    micPleaseWait: string,
    micListening: string,
    textInputPlaceholder: string,
    pressToStart: string,
}

const locStringsByLang: Map<ProxyLang, LocStrings> = new Map([
    [ProxyLang.ENG, {
        autoGreeting: 'Hello',
        micReady: 'Ready',
        micPleaseWait: 'Please wait',
        micListening: 'Listening',
        textInputPlaceholder: 'Write a question',
        pressToStart: 'Press here to start',
    }],
    [ProxyLang.GER, {
        autoGreeting: 'Hallo',
        micReady: 'Bereit',
        micPleaseWait: 'Warten',
        micListening: 'Hören',
        textInputPlaceholder: 'Schreibe eine Frage',
        pressToStart: 'Drücken Sie hier, um zu beginnen',
    }],
    [ProxyLang.ITA, {
        autoGreeting: 'Ciao',
        micReady: 'Pronto',
        micPleaseWait: 'Attendere',
        micListening: 'In ascolto',
        textInputPlaceholder: 'Scrivi una domanda',
        pressToStart: 'Premi qui per iniziare',
    }],
    [ProxyLang.FRA, {
        autoGreeting: 'Bonjour',
        micReady: 'Prêt',
        micPleaseWait: 'Attendez',
        micListening: 'Écoute',
        textInputPlaceholder: 'Rédiger une question',
        pressToStart: 'Appuyez ici pour commencer',
    }]
])

export const getAzureVoice = (lang: ProxyLang) => {
    const voice = azureVoices.get(lang)
    if (voice === undefined) throw new Error()
    return voice
}

const azureVoices: Map<ProxyLang, {lang: string, voice: string}> = new Map(Object.keys(AppConfig.voices).map(langStr => {
    if (!allProxyLangValues.has(langStr as any)) throw new Error("invalid language in config: " + langStr)
    return [langStr as ProxyLang, AppConfig.voices[langStr as ProxyLang]]
}))
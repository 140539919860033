import React, { CSSProperties } from 'react'
import { ProxyLang, enabledProxyLanguages } from '../Localization/locDef'
import { BaseFlex } from './Misc'
import { palette } from '../Misc/style'

export const LanguageSelector: React.FC<{lang: ProxyLang, onLangSelected: (l: ProxyLang) => void, containerStyle?: CSSProperties}> = props => {
    const [opened, setOpened] = React.useState(false)

    const flagButton = (l: ProxyLang, grayed: boolean, onPress: (l: ProxyLang) => void) => {
        const boxShadow = undefined//'#00000080 0.1em 0.1em 0.2em'
        return <BaseFlex style={{
            width: '100%',
            height: '100%',
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: '50%',
            backgroundColor: 'white',
            boxShadow: boxShadow,
            cursor: 'pointer',
            filter: grayed ? 'brightness(50%)' : undefined,
            marginBottom: '10%', // per distanziare i bottoni
            zIndex: 1,
        }} hoverBgColor={palette.buttonBgHovered} key={l} onPress={() => onPress(l)}>
            <img src={'images/flag-' + l + '.png'} style={{width: '60%', height: '60%', boxShadow: boxShadow}} />
        </BaseFlex>
    }
    
    return (
        <div style={{position: 'relative', width: '4em', height: '4em', overflow: 'visible', ...props.containerStyle}}>
            {flagButton(props.lang, opened, l => {
                setOpened(!opened)
            })}
            {opened && enabledProxyLanguages.map(l => flagButton(l, false, l2 => {
                props.onLangSelected(l2)
                setOpened(false)
            }))}
        </div>
    )
}
export const standardGap = '0.75em'

export const palette = {
    mainBg: 'grey',
    orientationBg: 'grey',
    inputBg: 'white',
    buttonBgHovered: 'lightgrey',
    pdfBg: 'grey',

    poweredByColor: 'grey',

    chatBg: 'black',
    chatSentBg: 'navy',
    chatSentText: 'white',
    chatReceivedBg: 'grey',
    chatReceivedText: 'white'
}
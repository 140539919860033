import React from 'react'
import * as DeviceDetect from 'react-device-detect'
import { ProxyLang } from '../Localization/locDef';


//export const isHorizontalLayout = () => !DeviceDetect.isMobile // commentato perchè non è più statico


export function usePrevious<T>(value: T) {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value; //assign the value of ref to the argument
  },[value]); //this code will run when the value of 'value' changes
  return ref.current; //in the end, return the current ref value.
}


export type ProxyLoginResp = {
  access_token: string,
  session_id: string,
}

export const requestProxyLogin = async (vampUrl: string): Promise<ProxyLoginResp> => {
  const settings: RequestInit = {
      method: 'POST',
      headers: {
          'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "username": "Teo",
        "password": "pas2021!",
      }),
  }
  try {
      const fetchResponse = await fetch(vampUrl + "/login", settings);
      const json = await fetchResponse.json();
      console.log(json)
      if (
        'access_token' in json && typeof json.access_token === 'string' &&
        'session_id' in json && typeof json.session_id === 'string'
      ) {
        return json
      } else {
        throw new Error('json content not as expected')
      }
  } catch (e) {
      console.log("reqVampAccessToken error: " + JSON.stringify(e))
      throw new Error()
  }    
}

type ProxyMessageReq = {
    "sessionID": string,
    "generator": string,
    "message": {
    "text": string,
    },
    "variables": {
        "tts": string,
        "customer_area": string,
    }
}

type ProxyMessageResp = {
    message: {
        multiple_questions?: object,
        question?: string,
        question_gpt3?: string,
        text?: string,
        tts?: string,
    },
    variables: {
        customer_area?: string,
        language?: string,
        ref?: string,
        tts?: string,
    }
}

export const sendMessageToProxy = async (proxyUrl: string, sessionId: string, language: ProxyLang, token: string, messageText: string) => {
  const request: ProxyMessageReq = {
    sessionID: sessionId,
    generator: 'Chatbot',
    message: {
      text: messageText,
    },
    variables: {
      tts: '',
      customer_area: 'Customer care',
    }
  }
  const settings: RequestInit = {
    method: 'POST',
    headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token,
    },
    body: JSON.stringify(request)
  }

  console.log("sending message to proxy:")
  console.log(request)

  let fetchResponse
  try {
      fetchResponse = await fetch(proxyUrl + "/engine/" + language, settings);
  } catch (e) {
      console.log("sendMessageToProxy error: " + JSON.stringify(e))
      throw e
  }
  const json = await fetchResponse.json();
  console.log("received response from proxy:")
  console.log(json)
  if (typeof json === 'object' && 'message' in json) {
    return json as ProxyMessageResp
  } else {
    throw new Error()
  }
}

export const decodeProxyMessageResponse = (resp: ProxyMessageResp) => {
  const question = resp.message.question_gpt3 || resp.message.question
  const readableText = resp.message.text ?? ''
  const ttsText = (resp.message.tts ?? '').replace('<speak>', '').replace('<break time=\"2s\"/></speak>', '') // rimuove roba che hanno lasciato da Polly di Amazon

  // per adesso choices disattivate
  const choices = null
  /*const choicesDoc = doc.getElementsByTagName('multiple_questions')?.[0]
  let choices: string[] | null
  if (!choicesDoc) {
    choices = null
  } else {
    choices = [
      choicesDoc.getElementsByTagName('question_1')?.[0]?.innerHTML,
      choicesDoc.getElementsByTagName('question_2')?.[0]?.innerHTML,
      choicesDoc.getElementsByTagName('question_3')?.[0]?.innerHTML,
      choicesDoc.getElementsByTagName('question_4')?.[0]?.innerHTML,
    ].filter(s => s ?? false)
  }*/

  const ref = resp.variables.ref

  // per adesso sinonimi disattivati
  const synonyms = null
  /*const synonyms = [
    variablesDoc?.getElementsByTagName('syn_topic')?.[0]?.innerHTML,
    variablesDoc?.getElementsByTagName('syn_action1')?.[0]?.innerHTML,
    variablesDoc?.getElementsByTagName('syn_action2')?.[0]?.innerHTML,
    variablesDoc?.getElementsByTagName('syn_subtopic')?.[0]?.innerHTML,
  ] as (string | undefined)[]*/

  // per adesso immagine disattivata
  const image = null
  //const image = variablesDoc?.getElementsByTagName('image')?.[0]?.innerHTML ?? ''

  // emozioni, che adesso non ci sono
  /*const exmlDoc = doc.getElementsByTagName('exml')?.[0]
  const emotions = {
    joy: Number(exmlDoc.getElementsByTagName('joy')?.[0]?.innerHTML ?? '0'),
    anticipation: Number(exmlDoc.getElementsByTagName('anticipation')?.[0]?.innerHTML ?? '0'),
    anger: Number(exmlDoc.getElementsByTagName('anger')?.[0]?.innerHTML ?? '0'),
    disgust: Number(exmlDoc.getElementsByTagName('disgust')?.[0]?.innerHTML ?? '0'),
    sadness: Number(exmlDoc.getElementsByTagName('sadness')?.[0]?.innerHTML ?? '0'),
    surprise: Number(exmlDoc.getElementsByTagName('surprise')?.[0]?.innerHTML ?? '0'),
    fear: Number(exmlDoc.getElementsByTagName('fear')?.[0]?.innerHTML ?? '0'),
    trust: Number(exmlDoc.getElementsByTagName('trust')?.[0]?.innerHTML ?? '0'),
  }*/

  return {
    question: question,
    readableText: readableText,
    ttsText: ttsText,
    choices: choices,
    ref: ref,
    synonyms: synonyms,
    image: image,
  }
}
export type DecodedProxyMessageResp = NonNullable<ReturnType<typeof decodeProxyMessageResponse>>
/*type Emotion = keyof DecodedVampResp['emotions']
type EmotionOrNull = Emotion | null*/
import React, { CSSProperties } from 'react'
import { MessageContent } from './ChatHistoryBox'
import { BaseFlex, CenterFlex, ColFlex, RowFlex } from './Misc'

import { Document, Page, pdfjs } from 'react-pdf'
import 'react-pdf/dist/Page/TextLayer.css'
import 'react-pdf/dist/Page/AnnotationLayer.css'
import { palette } from '../Misc/style'


// roba che serve a react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

export const OverlayScreen = (props: {horizontalLayout: boolean, content: MessageContent, onClosePress: () => void, containerStyle?: CSSProperties}) => {
    const [numPages, setNumPages] = React.useState(0)

    return (
        <CenterFlex expand style={{position: 'absolute', inset: 0, backgroundColor: '#00000080', padding: props.horizontalLayout ? '0 10%' : 0, ...props.containerStyle}}>
            <ColFlex expand style={{overflow: 'auto', alignItems: 'center', backgroundColor: palette.pdfBg}}>
                {props.content.type === 'pdf' ?
                    <Document file={props.content.url} onLoadSuccess={(event) => setNumPages(event.numPages)}>
                        {[...Array(numPages)].map((_, i) =>
                            <>
                                <Page pageNumber={i + 1} width={props.horizontalLayout ? 0.9 * window.innerWidth : 0.6 * window.innerWidth} />
                                <RowFlex style={{height: '1em'}} />
                            </>
                        )}
                    </Document> :
                null}
            </ColFlex>
            <img alt='exit' src='images/x-icon.svg' onClick={props.onClosePress} style={{position: 'absolute', top: 0, right: 0, padding: '1em', width: 'calc(min(10vw, 10vh))', opacity: 0.7, cursor: 'pointer', zIndex: 30}} />
        </CenterFlex>
    )
}
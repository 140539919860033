import React from 'react'
import * as DeviceDetect from 'react-device-detect'

export const BaseFlex: React.FC<{
    children?: any,
    style?: React.CSSProperties,
    onPress?: () => void,
    visible?: boolean,
    hoverBgColor?: string,
    expand?: boolean,
}> = props => {
    const [hovered, setHovered] = React.useState(false)

    const bgColor = props.hoverBgColor === undefined || !(hovered && DeviceDetect.isDesktop) ? props.style?.backgroundColor : props.hoverBgColor

    return <div
            style={{
                boxSizing: 'border-box',
                position: 'relative',
                display: props.visible === true || props.visible === undefined ? 'flex' : 'none',
                ...(props.expand ? {width: '100%', height: '100%'} : null),
                ...props.style,
                backgroundColor: bgColor,
                cursor: props.onPress ? 'pointer' : undefined,
            }}
            onClick={props.onPress}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}>
        {props.children}
    </div>
}

export const CenterFlex: typeof BaseFlex = props => {
    return <BaseFlex {...props} style={{alignItems: 'center', justifyContent: 'center', ...props.style}}>
        {props.children}
    </BaseFlex>
}

export const RowFlex: typeof BaseFlex = props => {
    return <BaseFlex {...props} style={{flexDirection: 'row', ...props.style}}>
        {props.children}
    </BaseFlex>
}

export const ColFlex: typeof BaseFlex = props => {
    return <BaseFlex {...props} style={{flexDirection: 'column', ...props.style}}>
        {props.children}
    </BaseFlex>
}

export const ExpCenterFlex: typeof CenterFlex = props =>
    <CenterFlex {...props} style={{width: '100%', height: '100%', ...props.style}}>
        {props.children}
    </CenterFlex>

export const ExpRowFlex: typeof RowFlex = props =>
    <RowFlex {...props} style={{width: '100%', height: '100%', ...props.style}}>
        {props.children}
    </RowFlex>

export const ExpColFlex: typeof ColFlex = props =>
    <ColFlex {...props} style={{width: '100%', height: '100%', ...props.style}}>
        {props.children}
    </ColFlex>
import React from 'react';
import logo from './logo.svg';
import './App.css';
import { MainScreen } from './Components/MainScreen';
import { DisplayOrientationScreen } from './Components/DisplayOrientationScreen';


function App() {
  return (
    <div className="App">
      <MainScreen />
      {/* <DisplayOrientationScreen shouldBeHorizontal={isHorizontalLayout()} /> commentato, non dice mai di girare lo schermo */}
    </div>
  );
}

export default App;
